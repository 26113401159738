@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid"; 

@font-face {
	font-family: 'Old Money Basic';
	src: url('../../dist/font/OldMoneyBasicRegular.woff2') format('woff2'),
			url('../../dist/font/OldMoneyBasicRegular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

html {
	background: $white;
	font-family: 'Raleway', sans-serif;
	font-size: 16px;
	line-height: 1;
	scroll-behavior: smooth;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	position: relative;
	&::before{
		content: "";
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-image: url('../../dist/img/bg.jpg');
		background-repeat: repeat;
		opacity: .24;
	}
	color: $black;
}

a {
	color: $black;

	@include hover-focus {
		color: $black;
	}
}

.social{
	@include flex(row,center,center);
	gap: 20px;
	a{
		font-size: 20px;
	}
}

h1,h2,h3,h4{
	font-family: $display;
	margin: 0;
	font-weight: 400;
}

p{
	margin: 0 0 20px;
	line-height: 1.4;
	font-size: 16px;
	@include tablet-up{
		font-size: 18px;
	}
}

img{
	max-width: 100%;
}

.section-heading{
	font-size: 32px;
	margin-bottom: 30px;
	text-align: center;
	@include tablet-up{
		font-size: 100px;
		margin-bottom: 60px;
	}
}

.btn{
	font-family: $display;
	text-align: center;
	display: inline-block;
	font-size: 18px;
	padding: 15px 30px;
	transition: ease-in-out .4s;
	@include tablet-up{
		font-size: 36px;
	}
	@include hover-focus{
		transform: translateY(-4px);
	}
	&.btn-black{
		background-color: $black;
		color: $white;
	}
}

header {
	position: relative;
	padding: 40px 0;
	.container{
		@include flex(column,center,center);
		gap: 20px;
		.logo{
			width: 100%;
			max-width: 500px
		}
		.nav{
			@include flex(row,center,center);
			flex-wrap: wrap;
			gap: 20px;
			list-style: none;
			margin: 0;
			padding: 0;
			li{
				a{
					text-transform: uppercase;
					font-family: $display;
					font-size: 16px;
					border-bottom: solid 2px transparent;
					@include hover-focus{
					  border-color: $black;
					}
				}
			}
		}
	}
	@include tablet-up{
		padding: 70px 0;
		.container{
			position: relative;
			.nav{
				li{
					a{
						font-size: 20px;
					}
				}
			}
			.social{
				position: absolute;
				right: 0;
				bottom: 0;
			}
		}
	}

}

main {
	position: relative;
	section{
		padding: 60px 0;
		@include tablet-up{
			padding: 120px 0
		}

		&#hero{
			padding: 0;
		}

		&#music{
			.container{
				.wrapper{
					@include flex(column,center,center);
					gap: 20px;
					img{
						max-width: 100%;
					}
					.content{
						@include flex(column,center,center);
						gap: 10px;
						h3{
							font-size: 15px;
						}
						h2{
							font-family: 'Raleway', sans-serif;
							text-transform: uppercase;
							font-size: 25px;
						}
					}
				}
			}
			@include tablet-up{
				.container{
					.wrapper{
						flex-direction: row;
						gap: 100px;
						img{
							flex: 1;
						}
						.content{
							flex: 1;
							align-items: flex-start;
							gap: 20px;
							h3{
								font-size: 30px;
							}
							h2{
								font-size: 50px;
							}
						}
					}
				}
			}
		}

		&#about{
			.container{
				.wrapper{
					img{
						@include tablet-up{
							margin-top: 100px;
						}
					}
				}
			}
		}

		&#videos{
			.container{
				.wrapper{
					iframe{
						width: 100%;
						aspect-ratio: 16 / 9;
						margin-bottom: 20px;
					}
					.wrapper{
						display: grid;
						gap: 20px;
						grid-template-columns: 1fr 1fr 1fr;
						img{
							cursor: pointer;
							width: 100%;
						}
					}
				}
			}
			@include tablet-up{
				.container{
					.wrapper{
						iframe{
							margin-bottom: 40px;
						}
						.wrapper{
							gap: 40px;
						}
					}
				}
			}
		}

		&#news{
			.container{
				text-align: center;
				.wrapper{
					@include flex(column,initial,initial);
					gap: 30px;
					margin-bottom: 60px;
					.new{
						text-align: center;
						display: grid;
						gap: 10px;
						.date{
							font-size: 13px;
							text-transform: uppercase;
						}
						p{
							margin: 0;
						}
						a{
							display: inline-block;
							img{
								width: 100%;
								height: 240px;
								object-fit: cover;
							}
						}
						h3{
							font-size: 20px;
						}
						.excerpt{
							font-size: 13px;
						}
						a{
							font-family: $display;
							font-size: 16px;
						}
					}
				}
			}
			@include tablet-up{
				.container{
					.wrapper{
						display: grid;
						grid-template-columns: repeat(
							auto-fit,
							minmax(
								30%,
								1fr
							)
						);
						.new{
							gap: 30px;
							h3{
								font-size: 24px;
							}
							&:nth-child(n + 4){
								display: none;
							}
						}
						&.all{
							.new{
								&:nth-child(n + 4){
									display: block;
								}
							}
						}
					}
				}
			}
		}

		&#tour{
			.container{
				.wrapper{
					position: relative;
					.bg{
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
					.content{
						position: relative;
						padding: 30px;
						text-align: center;
						.section-heading{
							margin-bottom: 0;
							background-color: rgba(255, 255, 255, 0.8);
							padding: 20px;
							margin-bottom: 20px;
						}
						#events{
							margin-bottom: 30px;
							display: grid;
							gap: 20px;
							.event{
								background-color: rgba(255, 255, 255, 0.8);
								padding: 20px;
								text-align: center;
								font-size: 16px;
								p{
									margin-bottom: 10px;
								}
								.btn{
									padding: 10px 30px;
								}
							}
						}
						>.btn{
							text-align: center;
						}
					}
				}
			}
			@include tablet-up{
				.container{
					.wrapper{
						.content{
							padding: 60px 60px 120px;
							text-align: right;
							max-width: 780px;
							margin-left: auto;
							.section-heading{
								font-size: 80px;
								line-height: 1;
								text-align: left;
								&.sub{
									font-size: 40px;
								}
							}
							#events{
								.event{
									font-size: 18px;
									@include flex(row,initial,center);
									text-align: left;
									p{
										flex: 1;
									}
									.btn{
										flex: 1;
										font-size: 20px;
									}
								}
							}
						}
					}
				}
			}
		}

		&#shop{
			.container{
				.wrapper{
					@include flex(column,center,center);
					gap: 30px;
					.merch{
						@include flex(column,center,center);
						gap: 20px;
						text-align: center;
						img{
							height: 300px;
						}
						h3{
							font-size: 21px;
						}
						p{
							font-size: 14px;
							margin-bottom: 0%;
						}
						.btn{
							font-size: 18px;
							padding: 10px 30px;
						}
					}
				}
			}
			@include tablet-up{
				.container{
					.wrapper{
						flex-direction: row;
						gap: 60px;
						justify-content: space-around;
						.merch{
							h3{
								font-size: 42px;
							}
						}
					}
				}
			}
		}

		

	}
}

footer {
	position: relative;
	padding: 60px 0;
	.container{
		text-align: center;
		@include flex(column,center,center);
		gap: 40px;
		img{
			display: inline-block;
			max-width: 260px;
		}
		.newsletter{
			h3{
				font-size: 21px;
				margin-bottom: 30px;
			}
			form{
				display: grid;
				gap: 20px;
				p{
					font-size: 12px;
				}
				.form-row{
					.form-col{
						input[type="text"],
						input[type="email"],
						input[type="number"]{
							background-color: transparent;
							border: solid 1px transparent;
							border-bottom-color: $black;
							border-radius: 0;
							color: $black;
							text-transform: uppercase;
							font-size: 16px;
							padding: 10px;
							width: 100%;
							&::placeholder{
								color: $black;
							}
							@include hover-focus{
								border-color: $black;
							}
						}
					}
				}
				>.form-row{
					.form-col{
						display: grid;
						gap: 20px;
						>.form-row{
							display: grid;
							gap: 20px;
						}
					}
				}
				.btn{
					@include hover-focus{
						transform: none;
					}
				}
			}
			.newsletter-alert{
				background-color: $black;
				color: $white;
				padding: 10px;
				font-size: 12px;
				text-transform: uppercase;
				display: none;
				&.success{
					background-color: green;
				}
				&.error{
					background-color: red;
				}
				&.active{
					display: block;
				}
			}
		}
		.copyright{
			p{
				margin-bottom: 0;
				font-size: 10px;
				text-transform: uppercase;
				line-height: 1.8;
				a{
					@include hover-focus{
						text-decoration: underline;
					}
				}
			}
		}
	}
	@include tablet-up{
		.container{
			.newsletter{
				h3{
					font-size: 42px;
				}
				form{
					>.form-row{
						&:nth-child(2){
							>.form-col{
								grid-template-columns: repeat(3, 1fr);
								>.form-row{
									&:first-child{
										grid-column: 1 / 3;
									}
									.form-row{
										display: grid;
										gap: 20px;
										grid-template-columns: repeat(2, 1fr);
									}
								}
							}
						}
					}
				}
			}
			.social{
				gap: 30px;
				a{
					font-size: 30px;
				}
			}
		}
	}
}

.music-links{
	@include flex(row,center,center);
	flex-wrap: wrap;
	gap: 15px;
	a{
		@include flex(row,center,center);
		font-size: 18px;
		background-color: $black;
		width: 40px;
		height: 40px;
		border-radius: 40px;
		color: $white;
		transition: all .4s;
		@include hover-focus{
			transform: scale(1.1);
		}
		svg{
			fill: $white;
			width: 15px;
			height: 15px;
			transform: translateX(2px);
		}
	}
	@include tablet-up{
		justify-content: flex-start;
	}
}